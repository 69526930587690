import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const TableContainer = styled.table`
  max-width: 792px;
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 2px;
  border-color: #000000;
  border-style: solid;
  color: #000000;
  margin-right: 25%;
  .left {
    width: 60%;
  }
  .right {
    width: 40%;
  }

  td,
  th {
    border-width: 2px;
    border-color: #000000;
    border-style: solid;
    padding: 5px !important;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  th {
    background: #444444;
    color: white;
    width: 15%;

    &:last-of-type {
      width: fit-content;
    }
  }

  thead {
    background-color: #c4c3bf;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }
`;

const Special = styled.table`
  max-width: 792px;
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 2px;
  border-color: #000000;
  border-style: solid;
  color: #000000;
  margin-right: 25%;
  .left {
    width: 60%;
  }
  .right {
    width: 40%;
  }

  td,
  th {
    border-width: 2px;
    border-color: #000000;
    border-style: solid;
    padding: 5px !important;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  th {
    background: #444444;
    color: white;
    width: 15%;

    &:last-of-type {
      width: fit-content;
    }
  }

  thead {
    background-color: #c4c3bf;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }
  @media (max-width: 1340px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
      border-collapse: collapse;
    }
    th {
      width: 100%;
      &:last-of-type {
        width: 100%;
      }
    }

    td {
      border: none;
      border-bottom: 1px solid gainsboro;
      position: relative;
    }
  }
`;

function Table({ type, children }) {
  return (
    <>
      {type ? (
        <TableContainer>{children}</TableContainer>
      ) : (
        <Special>{children}</Special>
      )}
    </>
  );
}

Table.propTypes = {
  type: PropTypes.bool,
};

Table.defaultProps = {
  type: true,
};

export default Table;
